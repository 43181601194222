/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import Pagination from './Pagination';
import { useTranslation } from 'react-i18next';
import { HomeIcon, CakeIcon, PuzzlePieceIcon, ExclamationCircleIcon, UserGroupIcon } from "@heroicons/react/20/solid"
import { motion } from "framer-motion"


const posts = [
    {
      id: 1,
      title: 'hotelService',
      imageUrl: '/assets/img/IMG_0089-min.png',
      icon: HomeIcon,
      alt: "Hotel para perros"
    },
    {
      id: 2,
      title: 'birthdayService',
      imageUrl: '/assets/img/IMG-20240803-WA0014.jpg',
      icon: CakeIcon,
      alt: "Cumpleaños para perros"
    },
    {
      id: 3,
      title: 'daycareService',
      imageUrl: '/assets/img/IMG-20240803-WA0009.jpg',
      icon: PuzzlePieceIcon,
      alt: "Hospedaje para perros"
    },
    {
      id: 4,
      title: 'specializedCareService',
      imageUrl: '/assets/img/IMG_7843-min.png',
      icon: ExclamationCircleIcon,
      alt: "Cuido especializado para mascotas"
    },
    {
      id: 5,
      title: 'meetGreetService',
      imageUrl: '/assets/img/IMG_8886-min.png',
      icon: UserGroupIcon,
      alt: "Evaluacion"
    },
  ]

  const variants = {
    easeOut: { 
      opacity: 1,
      y: 0,
      transition: {
        duration: 1
      }
    },
  }

  export default function ServiceCard() {

    const { t } = useTranslation()
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(3);
    const [animationKey, setAnimationKey] = useState(0);
  
    // Compute the number of pages based on the filtered data
    const totalPages = Math.ceil(posts.length / postsPerPage);
  
    // Compute the array of page numbers to display in the pagination component
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  
    // Get the index of the last post to display on the current page
    const indexOfLastPost = currentPage * postsPerPage;
  
    // Get the index of the first post to display on the current page
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
  
    // Get the current page of posts
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
  
    // Handle changing the current page of posts
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };

    useEffect(() => {
      setAnimationKey(animationKey + 1)
    }, [currentPage]);

    return (
      <div className="bg-custom-light-green py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">{t('changesACBY')}</h2>
            <p className="mt-2 text-lg leading-8 text-white">
              <a className="hover:underline" href="/services">{t('servicesOverview')}</a>
            </p>
          </div>
          <motion.div key={animationKey} initial={{ y: 25, opacity: 0 }} animate={"easeOut"} variants={variants} className="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {currentPosts.map((post) => (
              <article
                key={post.id}
                className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"
              >
                <img src={post.imageUrl} alt={post.alt} className="absolute inset-0 -z-10 h-full w-full object-cover" />
                <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
  
                <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
                  <time className="mr-8"></time>
                  <div className="-ml-4 flex items-center gap-x-4">
                    <div className="flex gap-x-2.5"></div>
                  </div>
                </div>
                <h3 className="mt-3 flex text-2xl font-semibold leading-6 text-white">
                  <a href='/services'>
                    <span className="absolute inset-0" />
                    {t(post.title)}
                  </a>
                  <post.icon className="ml-2 h-6 w-6" aria-hidden="true" />
                </h3>
              </article>
            ))}
          </motion.div>
          <div className='mt-6'>
          <Pagination totalPages={totalPages} currentPage={currentPage} pageNumbers={pageNumbers} onPageChange={handlePageChange} />
          </div>
        </div>
      </div>
    )
  }
  