import { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import { useTranslation } from 'react-i18next';

export default function Example() {
  const { t } = useTranslation();
  const container = useRef(null);
  const isInView = useInView(container);
  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start({
        x: 0,
        transition: {
          type: 'spring', duration: 2, bounce: 0.2
        }
      });
    }
  }, [isInView, animation]);

  return (
    <div className="bg-custom-green">
      <section aria-labelledby="features-heading" className="relative">
        <div className="aspect-w-3 aspect-h-2 overflow-hidden sm:aspect-w-5 lg:aspect-none lg:absolute lg:h-full lg:w-1/2 right-0 lg:left-auto lg:pl-4 xl:pl-16">
          <img
            src="/assets/img/house-of-cats.jpg"
            alt="Cuido especializado para perros y otras mascotas"
            className="h-full w-full object-cover object-center lg:h-full lg:w-full"
          />
        </div>

        <div ref={container} className="mx-auto max-w-2xl px-4 pt-16 pb-24 sm:px-6 sm:pb-32 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:pt-32">
          <motion.div initial={{ x: '-100vw' }} animate={animation} className="lg:col-start-1">
            {/* Cat Hotel Section */}
            <p className="mt-4 text-4xl font-bold tracking-tight text-white">{t('catHotel')}</p>
            <p className="mt-4 font-medium text-gray-900">{t('catHotelPrice')}</p>
            <p className="mt-4 text-gray-700">{t('catHotelDescription')}</p>

            {/* Cat Hotel Specialized Section */}
            <p className="mt-12 text-4xl font-bold tracking-tight text-white">{t('catHotelSpecialized')}</p>
            <p className="mt-4 text-gray-700">{t('catHotelSpecializedDescription')}</p>

            {/* Specialized Features */}
            <div className="mt-8">
              <p className="font-bold text-gray-900">{t('catHotelSpecializedExpertMedicalSupervisionTitle')}:</p>
              <p className="text-gray-700">{t('catHotelSpecializedExpertMedicalSupervisionDescription')}</p>
            </div>

            <div className="mt-4">
              <p className="font-bold text-gray-900">{t('catHotelSpecializedComfortableRetreatTitle')}:</p>
              <p className="text-gray-700">{t('catHotelSpecializedComfortableRetreatDescription')}</p>
            </div>

            <div className="mt-4">
              <p className="font-bold text-gray-900">{t('catHotelSpecializedTailoredComfortCompanionshipTitle')}:</p>
              <p className="text-gray-700">{t('catHotelSpecializedTailoredComfortCompanionshipDescription')}</p>
            </div>

            <div className="mt-4">
              <p className="font-bold text-gray-900">{t('catHotelSpecializedAlwaysWatchfulTitle')}:</p>
              <p className="text-gray-700">{t('catHotelSpecializedAlwaysWatchfulDescription')}</p>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
}
