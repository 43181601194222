import { useEffect, useRef} from "react"
import { motion, useInView, useAnimation } from "framer-motion"
import { useTranslation } from 'react-i18next';
  
  export default function About() {

    const { t } = useTranslation()

    const container = useRef(null)
    const isInView = useInView(container)
    const textAnimation = useAnimation()
    const imgAnimation = useAnimation()

    useEffect(() => {
      if(isInView) {
        textAnimation.start({
          x: 0,
          transition: {
            type: 'spring', duration: 2, bounce: 0.2
          }
        })

        imgAnimation.start({
          opacity: 1,
          transition: {
            type: 'ease',
            duration: 1.5,
            ease: 'easeOut'
          }
        })

      }
    }, [isInView, textAnimation, imgAnimation]);

    return (
      <div className="bg-custom-green">
        <div className="mx-auto max-w-2xl py-24 px-4 sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8">
          <div ref={container} className="grid grid-cols-1 items-center gap-y-16 gap-x-8 lg:grid-cols-2">
            <motion.div initial={{ x: '-100vw' }} animate={textAnimation}>
              <div className="border-b border-gray-200 pb-10">
                <h2 className="font-medium text-white">{t('animalCareByYaraliz')}</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">{t('about')}</p>
              </div>
  
                <div className="prose prose-indigo mx-auto mt-5 text-white lg:col-start-1 lg:row-start-1 lg:max-w-none">
                  <p>{t('aboutDescriptionHeading')}</p>
                  <p>{t('aboutDescriptionOne')}</p>
                  <p>{t('aboutDescriptionTwo')}</p>
                  <p>{t('aboutDescriptionThree')}</p>
              </div>
            </motion.div>
  
            <div>
              <motion.div initial={{ opacity: 0 }} animate={imgAnimation} className="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg bg-gray-100">
                <img
                  src="/assets/img/IMG_1222-min.png"
                  alt="Hospedaje para perros"
                  className="h-full w-full object-cover shadow-lg object-center"
                />
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  