import { BuildingOffice2Icon } from "@heroicons/react/24/outline";
import { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function Example() {
  const { t } = useTranslation();
  const container = useRef(null);
  const isInView = useInView(container);
  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start({
        y: 0,
        opacity: 1,
        transition: {
          type: "ease",
          duration: 1.5,
          ease: "easeOut",
        },
      });
    }
  }, [isInView, animation]);

  return (
    <div ref={container}>
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        animate={animation}
        className="relative bg-custom-rose"
      >
        <div className="mx-auto max-w-7xl">
          <div className="relative z-10 lg:w-full lg:max-w-2xl">
            <div className="relative py-32 px-6 sm:py-40 lg:py-56 lg:px-8 lg:pr-0">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                <h2 className="text-3xl font-bold tracking-tight text-white">
                  {t("contactHeading")}
                </h2>
                <p className="mt-6 text-lg leading-8 text-gray-100">
                  {t("contactDescription")}
                </p>
                <dl className="mt-5 space-y-4 text-base leading-7 text-gray-100">
                  <div className="flex gap-x-4 items-center mb-6">
                    {" "}
                    {/* Added margin-bottom here */}
                    <dt className="flex-none">
                      <span className="sr-only">Telephone</span>
                    </dt>
                    <dd>
                      <button
                        className="bg-custom-light-green text-white font-bold py-2 px-4 rounded hover:bg-custom-green transition transform -translate-x-4"
                        onClick={() =>
                          window.open(
                            "https://acby.portal.gingrapp.com/",
                            "_blank",
                            "noopener,noreferrer"
                          )
                        }
                      >
                        {t("contactGinger")}
                      </button>
                    </dd>
                  </div>
                  <div className="flex gap-x-4">
                    <dt className="flex-none">
                      <span className="sr-only">Address</span>
                      <BuildingOffice2Icon
                        className="h-7 w-6 text-white"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd>
                      Cam Eduviges Rivera Castro
                      <br />
                      Trujillo Alto 00976, Puerto Rico
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <iframe
            title="Google"
            className="w-full h-full xs:h-96 sm:h-96 lg:h-full xl:h-full"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2639.555631657492!2d-66.00956750143295!3d18.338544373056873!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c035da122452459%3A0xad6f58be52754b4b!2sAnimal%20Care%20by%20Yaraliz!5e0!3m2!1sen!2sus!4v1678920636113!5m2!1sen!2sus"
            style={{ border: 0 }}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </motion.div>
    </div>
  );
}
