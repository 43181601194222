/* eslint-disable no-sequences */

import { useEffect, useRef} from "react"
import { useMotionValue, useTransform, animate, motion, useInView } from "framer-motion"
import { useTranslation } from 'react-i18next';

  export default function Example() {

    const { t } = useTranslation()

    // First
    const firstCount = useMotionValue(0)
    const firstRounded = useTransform(firstCount, latest => Math.round(latest))

    // Second
    const secondCount = useMotionValue(0)
    const secondRounded = useTransform(secondCount, latest => Math.round(latest))

    // Third
    const thirdCount = useMotionValue(0)
    const thirdRounded = useTransform(thirdCount, latest => Math.round(latest))

    // Third
    const fourthCount = useMotionValue(0)
    const fourthRounded = useTransform(fourthCount, latest => Math.round(latest))

    const stats = [
      { id: 1, name: 'statsOne', value: firstRounded },
      { id: 2, name: 'statsTwo', value: secondRounded },
      { id: 3, name: 'statsThree', value: thirdRounded },
      { id: 4, name: 'statsFour', value: fourthRounded },
    ]

    const container = useRef(null)
    const isInView = useInView(container)

    useEffect(() => {
      if(isInView) {
        const firstAnimation = animate(firstCount, 30, { duration: 3 });
        const secondAnimation = animate(secondCount, 720, { duration: 3 });
        const thirdAnimation = animate(thirdCount, 450, { duration: 3 });
        const fourthAnimation = animate(fourthCount, 50, { duration: 3 });

        return firstAnimation.stop, secondAnimation.stop, thirdAnimation.stop, fourthAnimation.stop
      }
    }, [isInView, firstCount, secondCount, thirdCount, fourthCount]);

    return (
      <div className="bg-custom-light-orange py-12 sm:py-24">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <dl className="grid grid-cols-1 gap-y-16 gap-x-8 text-center lg:grid-cols-4">
            {stats.map((stat) => (
              <div key={stat.id} className="mx-auto flex max-w-xs flex-col gap-y-4">
                <dt className="text-base leading-7 text-gray-200">{t(stat.name)}</dt>
                <div ref={container} className="flex mx-auto order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">
                  <motion.p>
                    {stat.value}
                  </motion.p>
                  <span>+</span>
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>
    )
  }
  