import { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function Example() {
  const { t } = useTranslation();
  const container = useRef(null);
  const isInView = useInView(container);
  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 2,
          bounce: 0.2,
        },
      });
    }
  }, [isInView, animation]);

  return (
    <div className="bg-custom-light-green">
      <section aria-labelledby="features-heading" className="relative">
        <div className="aspect-w-3 aspect-h-2 overflow-hidden sm:aspect-w-5 lg:aspect-none lg:absolute lg:h-full lg:w-1/2 right-0 lg:left-auto lg:pl-4 xl:pl-16">
          <img
            src="/assets/img/IMG-20240803-WA0007.jpg"
            alt="Cuido especializado para perros y otras mascotas"
            className="h-full w-full object-cover object-center lg:h-full lg:w-full"
          />
        </div>

        <div
          ref={container}
          className="mx-auto max-w-2xl px-4 pt-16 pb-24 sm:px-6 sm:pb-32 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:pt-32"
        >
          <motion.div
            initial={{ x: "-100vw" }}
            animate={animation}
            className="lg:col-start-1"
          >
            {/* Main Title and Subtitle */}
            <p className="mt-4 text-4xl font-bold tracking-tight text-white">
              {t("dogHotelTitle")}
            </p>
            <p className="mt-4 text-gray-600">{t("dogHotelSubTitle")}</p>
            <p className="mt-4 text-gray-600">{t("dogHotelDescription")}</p>
            <p className="mt-4 text-gray-600">{t("dogHotelSubDescription")}</p>

            {/* Daytime Delights */}
            <p className="mt-8 font-bold text-gray-700">
              {t("dogHotelDaytimeDelightsTitle")}:
              <span className="font-normal text-gray-600">
                {" "}
                {t("dogHotelDaytimeDelightsDescription")}
              </span>
            </p>

            {/* Playtime Galore */}
            <p className="mt-4 font-bold text-gray-700">
              {t("dogHotelPlaytimeGaloreTitle")}:
              <span className="font-normal text-gray-600">
                {" "}
                {t("dogHotelPlaytimeGaloreDescription")}
              </span>
            </p>

            {/* Dreamy Nights */}
            <p className="mt-4 font-bold text-gray-700">
              {t("dogHotelDreamyNightsTitle")}:
              <span className="font-normal text-gray-600">
                {" "}
                {t("dogHotelDreamyNightsDescription")}
              </span>
            </p>

            {/* Security and Comfort Combined */}
            <p className="mt-4 font-bold text-gray-700">
              {t("dogHotelSecurityComfortCombinedTitle")}:
              <span className="font-normal text-gray-600">
                {" "}
                {t("dogHotelSecurityComfortCombinedDescription")}
              </span>
            </p>

            {/* Sweet Dreams and Wagging Tails Await */}
            <p className="mt-4 font-bold text-gray-700">
              {t("dogHotelSweetDreamsWaggingTailsTitle")}:
              <span className="font-normal text-gray-600">
                {" "}
                {t("dogHotelSweetDreamsWaggingTailsDescription")}
              </span>
            </p>
          </motion.div>
        </div>
      </section>
    </div>
  );
}
