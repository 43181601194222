
import ServicioUno from '../components/ServicioUno';
import ServicioDos from '../components/ServicioDos';
import ServicioTres from '../components/ServicioTres';
import ServicioCuatro from '../components/ServicioCuatro';
import ServicioCinco from '../components/ServicioCinco';
import ServiceHeader from '../components/ServiceHeader';
import ServicioSeis from '../components/ServicioSeis';


export default function Services() {
    return (
        <div className="bg-custom-light-orange">
            <ServiceHeader />
            <ServicioCinco />
            <ServicioDos />
            <ServicioTres />
            <ServicioCuatro />
            <ServicioUno />
            <ServicioSeis />
        </div>
    )
}