import { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function Example() {
  const { t } = useTranslation();
  const container = useRef(null);
  const isInView = useInView(container);
  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 2,
          bounce: 0.2,
        },
      });
    }
  }, [isInView, animation]);

  return (
    <div className="bg-custom-beige">
      <section aria-labelledby="features-heading" className="relative">
        <div className="aspect-w-3 aspect-h-2 overflow-hidden sm:aspect-w-5 lg:aspect-none lg:absolute lg:h-full lg:w-1/2 lg:pr-4 xl:pr-16">
          <img
            src="/assets/img/IMG-20240803-WA0013.jpg"
            alt="Hotel para perros y otras mascotas"
            className="h-full w-full object-cover object-center lg:h-full lg:w-full"
          />
        </div>

        <div
          ref={container}
          className="mx-auto max-w-2xl px-4 pt-16 pb-24 sm:px-6 sm:pb-32 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:pt-32"
        >
          <motion.div
            initial={{ x: "100vw" }}
            animate={animation}
            className="lg:col-start-2"
          >
            <p className="mt-4 text-4xl font-bold tracking-tight text-gray-900">
              {t("dogHotelSpecialTreatmentTitle")}
            </p>
            <p className="mt-4 font-medium text-gray-700">
              {t("dogHotelSpecialTreatmentSubTitle")}
            </p>
            <p className="mt-4 font-medium text-gray-700">
              {t("dogHotelSpecialTreatmentTitleDescription")}
            </p>
            <p className="mt-2 text-gray-500">
              {t("dogHotelSpecialTreatmentDescription")}
            </p>

            {/* Unneutered Dogs */}
            <p className="mt-8 font-bold text-gray-700">
              {t("dogHotelSpecialTreatmentUnneuteredDogsTitle")}:
              <span className="font-normal text-gray-500">
                {" "}
                {t("dogHotelSpecialTreatmentUnneuteredDogsDescription")}
              </span>
            </p>

            {/* Brachycephalic Dogs */}
            <p className="mt-4 font-bold text-gray-700">
              {t("dogHotelSpecialTreatmentBrachycephalicDogsTitle")}:
              <span className="font-normal text-gray-500">
                {" "}
                {t("dogHotelSpecialTreatmentBrachycephalicDogsDescription")}
              </span>
            </p>

            {/* Senior Dogs */}
            <p className="mt-4 font-bold text-gray-700">
              {t("dogHotelSpecialTreatmentSeniorDogsTitle")}:
              <span className="font-normal text-gray-500">
                {" "}
                {t("dogHotelSpecialTreatmentSeniorDogsDescription")}
              </span>
            </p>

            {/* Shy or Anxious Dogs */}
            <p className="mt-4 font-bold text-gray-700">
              {t("dogHotelSpecialTreatmentShyAnxiousDogsTitle")}:
              <span className="font-normal text-gray-500">
                {" "}
                {t("dogHotelSpecialTreatmentShyAnxiousDogsDescription")}
              </span>
            </p>

            <p className="mt-4 text-red-500 font-bold">{t("disclaimer")}:</p>
            <p className="text-gray-500 font-medium">
              {t("noCheckInOutSchedule")}
            </p>
            <p className="text-gray-500 font-medium">
              {t("noCheckInOutHolidays")}
            </p>

            {/* New Section: Dog Hotel Specialized and Critical Care */}
            <p className="mt-8 text-4xl font-bold tracking-tight text-gray-900">
              {t("dogHotelCriticalCareTitle")}
            </p>
            <p className="mt-4 font-medium text-gray-700">
              {t("dogHotelCriticalCareSubTitle")}
            </p>
            <p className="mt-4 text-gray-500">
              {t("dogHotelCriticalCareDescription")}
            </p>

            {/* Expert Medical Supervision */}
            <p className="mt-8 font-bold text-gray-700">
              {t("dogHotelCriticalCareExpertMedicalSupervisionTitle")}:
              <span className="font-normal text-gray-500">
                {" "}
                {t("dogHotelCriticalCareExpertMedicalSupervisionDescription")}
              </span>
            </p>

            {/* Comfortable Retreat */}
            <p className="mt-4 font-bold text-gray-700">
              {t("dogHotelCriticalCareComfortableRetreatTitle")}:
              <span className="font-normal text-gray-500">
                {" "}
                {t("dogHotelCriticalCareComfortableRetreatDescription")}
              </span>
            </p>

            {/* Tailored Comfort and Companionship */}
            <p className="mt-4 font-bold text-gray-700">
              {t("dogHotelCriticalCareTailoredComfortCompanionshipTitle")}:
              <span className="font-normal text-gray-500">
                {" "}
                {t(
                  "dogHotelCriticalCareTailoredComfortCompanionshipDescription"
                )}
              </span>
            </p>

            {/* Always Watchful */}
            <p className="mt-4 font-bold text-gray-700">
              {t("dogHotelCriticalCareAlwaysWatchfulTitle")}:
              <span className="font-normal text-gray-500">
                {" "}
                {t("dogHotelCriticalCareAlwaysWatchfulDescription")}
              </span>
            </p>
          </motion.div>
        </div>
      </section>
    </div>
  );
}
