
import React, { useState, useEffect } from "react";
import NewHeader from '../components/NewHeader';
import ServiceCard from '../components/ServiceCard';
import CTA from '../components/CTA';
import Review from '../components/Review';
import Stats from '../components/Stats';
import { motion } from 'framer-motion';
import BookingModal from '../components/BookingModal'; // Import the BookingModal component


export default function Home() {

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
      setTimeout(() => setShowModal(true), 1500)
    }, []); // open the modal when the component mounts

    return (
        
        <div className="bg-custom-light-orange">
          {showModal &&
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, ease: 'easeOut' }}
          >
          <BookingModal onClose={() => setShowModal(false)} />
          </motion.div>
          }
            <NewHeader />
            <Stats />
            <ServiceCard />
            <CTA />
            <Review />
        </div>
    )
}