import { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function Example() {
  const { t } = useTranslation();
  const container = useRef(null);
  const isInView = useInView(container);
  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 2,
          bounce: 0.2,
        },
      });
    }
  }, [isInView, animation]);

  return (
    <div className="bg-custom-beige">
      <section aria-labelledby="features-heading" className="relative">
        <div className="aspect-w-3 aspect-h-2 overflow-hidden sm:aspect-w-5 lg:aspect-none lg:absolute lg:h-full lg:w-1/2 lg:pr-4 xl:pr-16">
          <img
            src="/assets/img/IMG-20240803-WA0016.jpg"
            alt="Hospedaje para perros y otras mascotas"
            className="h-full w-full object-cover object-center lg:h-full lg:w-full"
          />
        </div>

        <div
          ref={container}
          className="mx-auto max-w-2xl px-4 pt-16 pb-24 sm:px-6 sm:pb-32 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:pt-32"
        >
          <motion.div
            initial={{ x: "100vw" }}
            animate={animation}
            className="lg:col-start-2"
          >
            <p className="mt-4 text-4xl font-bold tracking-tight text-gray-900">
              {t("dayCareTitle")}
            </p>
            <p className="mt-4 text-gray-500">{t("dayCareSubTitle")}</p>
            <p className="mt-4 text-gray-500">{t("dayCareDescription")}</p>

            {/* Playtime Extravaganza */}
            <p className="mt-8 font-bold text-gray-700">
              {t("dayCarePlayTimeExtravaganzaTitle")}:
              <span className="font-normal text-gray-500">
                {" "}
                {t("dayCarePlayTimeExtravaganzaDescription")}
              </span>
            </p>

            {/* Treats Galore */}
            <p className="mt-4 font-bold text-gray-700">
              {t("dayCareTreatsGaloreTitle")}:
              <span className="font-normal text-gray-500">
                {" "}
                {t("dayCareTreatsGaloreDescription")}
              </span>
            </p>

            {/* Splash Zone Adventure */}
            <p className="mt-4 font-bold text-gray-700">
              {t("dayCareSplahZoneAdventureTitle")}:
              <span className="font-normal text-gray-500">
                {" "}
                {t("dayCareSplahZoneAdventureDescription")}
              </span>
            </p>

            {/* Socialization Central */}
            <p className="mt-4 font-bold text-gray-700">
              {t("dayCareSocializationCentralTitle")}:
              <span className="font-normal text-gray-500">
                {" "}
                {t("dayCareSocializationCentralDescription")}
              </span>
            </p>

            {/* Cool Pup Popsicles */}
            <p className="mt-4 font-bold text-gray-700">
              {t("dayCareCoolPupPopsiclesTitle")}:
              <span className="font-normal text-gray-500">
                {" "}
                {t("dayCareCoolPupPopsiclesDescription")}
              </span>
            </p>

            {/* Memories in the Making */}
            <p className="mt-4 font-bold text-gray-700">
              {t("dayCareMemoriesInTheMakingTitle")}:
              <span className="font-normal text-gray-500">
                {" "}
                {t("dayCareMemoriesInTheMakingDescription")}
              </span>
            </p>
          </motion.div>
        </div>
      </section>
    </div>
  );
}
