import { StarIcon } from "@heroicons/react/20/solid";
import { useEffect, useRef, useState } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import { useTranslation } from "react-i18next";
import axios from "axios";

const options = { year: "numeric", month: "long", day: "numeric" };

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const { t } = useTranslation();
  const container = useRef(null);
  const isInView = useInView(container);
  const animation = useAnimation();

  useEffect(() => {
    async function fetchData() {
      const storedReviews = sessionStorage.getItem("reviews");

      if (storedReviews) {
        setData(JSON.parse(storedReviews));
      } else {
        setIsLoading(true);

        try {
          const response = await axios.get(
            "https://us-central1-animalcarebyyaraliz-dbeda.cloudfunctions.net/getPlaceDetails",
            {
              params: {
                placeId: "ChIJWSRFIqFdA4wRS0t1Ur5Yb60",
              },
            }
          );
          setData(response.data);
          sessionStorage.setItem("reviews", JSON.stringify(response.data));
        } catch (error) {
          console.error("Error fetching data");
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (isInView) {
      animation.start({
        y: 0,
        opacity: 1,
        transition: {
          type: "ease",
          duration: 1.5,
          ease: "easeOut",
        },
      });
    }
  }, [isInView, animation]);

  return (
    <div ref={container} className="bg-custom-green py-24 md:py-32">
      <motion.div
        initial={{ y: 200 }}
        animate={animation}
        className="mx-auto grid max-w-7xl grid-cols-1 gap-y-20 gap-x-8 px-6"
      >
        {isLoading && (
          <div className="text-center">
            <p className="text-xl font-bold text-white">
              {t("loadingReviews")}
              <span className="ml-2 inline-block animate-spin rounded-full h-4 w-4 border-b-2 border-white"></span>
            </p>
          </div>
        )}
        {!isLoading && data && (
          <div>
            <h2 className="sr-only">Customer Reviews</h2>

            <div>
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                {t("reviews")}
              </h2>
              <a
                href="https://g.page/r/CUtLdVK-WG-tEB0/review"
                className="mt-0 text-lg leading-8 text-white hover:cursor-pointer hover:underline"
              >
                {t("reviewsDescription")}
              </a>
              {/* <div className="mt-4 flex items-center">
                    {[0, 1, 2, 3, 4].map((rating) => (
                      <StarIcon
                        key={rating}
                        className={classNames(
                          data.rating > rating ? 'text-yellow-400' : 'text-white',
                          'h-5 w-5 flex-shrink-0'
                        )}
                        aria-hidden="true"
                      />
                    ))}
                    <p className='text-white ml-3'>{data.rating} {t('outOf5Stars')}</p>
                </div> */}
            </div>
            <div className="-my-10 mt-1">
              {data.reviews.map((review, reviewIdx) => (
                <div
                  key={reviewIdx}
                  className="flex space-x-4 text-sm text-white"
                >
                  <div className="flex-none py-10">
                    <a href={review.author_url}>
                      <img
                        src={review.profile_photo_url}
                        alt="animal-care-by-yaraliz-review"
                        className="h-10 w-10 rounded-full bg-gray-100"
                      />
                    </a>
                  </div>
                  <div
                    className={classNames(
                      reviewIdx === 0 ? "" : "border-t border-gray-200",
                      "flex-1 py-10"
                    )}
                  >
                    <h3 className="font-medium text-white">
                      <a href={review.author_url} className="hover:underline">
                        {review.author_name}
                      </a>
                    </h3>
                    <p>
                      <time dateTime={review.time}>
                        {new Date(review.time * 1000).toLocaleString(
                          "en-US",
                          options
                        )}{" "}
                        - {review.relative_time_description}
                      </time>
                    </p>

                    <div className="mt-4 flex items-center">
                      {[0, 1, 2, 3, 4].map((rating) => (
                        <StarIcon
                          key={rating}
                          className={classNames(
                            review.rating > rating
                              ? "text-yellow-400"
                              : "text-white",
                            "h-5 w-5 flex-shrink-0"
                          )}
                          aria-hidden="true"
                        />
                      ))}
                      <p className="ml-3">
                        {review.rating} {t("outOf5Stars")}
                      </p>
                    </div>
                    <p className="sr-only">
                      {review.rating} {t("outOf5Stars")}
                    </p>

                    <div
                      className="prose prose-sm mt-4 max-w-none text-white"
                      dangerouslySetInnerHTML={{ __html: review.text }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </motion.div>
    </div>
  );
}
