/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { Dialog } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  HomeIcon,
  PhoneIcon,
  CameraIcon,
  ClipboardDocumentCheckIcon,
  BuildingStorefrontIcon,
} from "@heroicons/react/20/solid";
import Dropdown from "../components/Dropdown";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";

export default function Example() {
  const { t, i18n } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    location.reload();
  };

  return (
    <nav className="bg-custom-light-orange shadow">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 justify-between">
          <div className="flex">
            <div className="flex flex-shrink-0 items-center">
              <a href="/">
                <img
                  className="block h-12 w-auto"
                  src="assets/img/animalcarebyyaraliz-logo.png"
                  alt="Animal Care by Yaraliz Logo"
                />
              </a>
            </div>
          </div>
          <div className="hidden sm:ml-6 sm:flex">
            <div className="hidden sm:ml-6 sm:flex sm:items-center sm:space-x-8">
              <a
                href="/"
                className="flex items-center whitespace-nowrap hover:border-b-2 hover:border-gray-300 px-1 pt-1 text-md font-medium text-white"
              >
                {t("home")}
              </a>
              <a
                href="/about"
                className="flex items-center whitespace-nowrap hover:border-b-2 hover:border-gray-300 px-1 pt-1 text-md font-medium text-white"
              >
                {t("about")}
              </a>
              <a
                href="/services"
                className="flex items-center whitespace-nowrap hover:border-b-2 hover:border-gray-300 px-1 pt-1 text-md font-medium text-white"
              >
                {t("services")}
              </a>
              <a
                href="/gallery"
                className="flex items-center whitespace-nowrap hover:border-b-2 px-1 pt-1 text-md font-medium text-white hover:border-gray-300 hover:text-white"
              >
                {t("gallery")}
              </a>
              <a
                href="/contact"
                className="flex items-center whitespace-nowrap hover:border-b-2 px-1 pt-1 text-md font-medium text-white hover:border-gray-300 hover:text-white"
              >
                {t("contact")}
              </a>
              <Dropdown />
            </div>
          </div>
          <div className="-mr-2 flex justify-between items-center sm:hidden">
            {/* Mobile menu button */}
            <button
              className="flex items-center justify-center rounded-md p-2 text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Sidebar */}
      <AnimatePresence>
        {sidebarOpen && (
          <Dialog
            as="div"
            className="fixed inset-0 z-50 flex sm:hidden lg:block"
            open={sidebarOpen}
            onClose={() => setSidebarOpen(false)}
            static
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
            <motion.div
              className="relative flex flex-col w-64 max-w-xs bg-custom-light-orange p-6"
              initial={{ x: "-100%" }}
              animate={{ x: 0 }}
              exit={{ x: "-100%" }}
              transition={{ duration: 0.3 }}
            >
              <div className="flex items-center justify-between mb-5">
                <img
                  className="h-12 w-12"
                  src="assets/img/animalcarebyyaraliz-logo.png"
                  alt="Animal Care by Yaraliz Logo"
                />
                <button className="" onClick={() => setSidebarOpen(false)}>
                  <XMarkIcon
                    className="block h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Close menu</span>
                </button>
              </div>
              <div className="flex-1 overflow-y-auto">
                <nav className="space-y-1">
                  <a
                    href="/"
                    className="flex items-center py-2 text-base font-medium text-white hover:bg-custom-rose"
                  >
                    <HomeIcon className="h-7 w-7 text-white pr-1" />
                    {t("home")}
                  </a>
                  <a
                    href="/about"
                    className="flex items-center py-2 text-base font-medium text-white hover:bg-custom-rose"
                  >
                    <ClipboardDocumentCheckIcon className="h-7 w-7 text-white pr-1" />
                    {t("about")}
                  </a>
                  <a
                    href="/services"
                    className="flex items-center py-2 text-base font-medium text-white hover:bg-custom-rose"
                  >
                    <BuildingStorefrontIcon className="h-7 w-7 text-white pr-1" />
                    {t("services")}
                  </a>
                  <a
                    href="/gallery"
                    className="flex items-center py-2 text-base font-medium text-white hover:bg-custom-rose"
                  >
                    <CameraIcon className="h-7 w-7 text-white pr-1" />
                    {t("gallery")}
                  </a>
                  <a
                    href="/contact"
                    className="flex items-center py-2 text-base font-medium text-white hover:bg-custom-rose"
                  >
                    <PhoneIcon className="h-7 w-7 text-white pr-1" />
                    {t("contact")}
                  </a>
                  <div className="flex items-center">
                    <img
                      className="h-6 w-6 hover:border hover:border-white"
                      src="/assets/img/flags/4x3/us.svg"
                      alt="United States"
                    />
                    <a
                      onClick={() => changeLanguage("en")}
                      className="bg-custom-light-orange text-base font-medium text-gray-100 font-bold block px-4 py-2 cursor-pointer"
                    >
                      English
                    </a>
                  </div>
                  <div className="flex items-center">
                    <img
                      className="h-6 w-6 hover:border hover:border-white"
                      src="/assets/img/flags/4x3/pr.svg"
                      alt="Puerto Rico"
                    />
                    <a
                      onClick={() => changeLanguage("es")}
                      className="bg-custom-light-orange text-base font-medium text-gray-100 font-bold block px-4 py-2 cursor-pointer"
                    >
                      Español
                    </a>
                  </div>
                </nav>
              </div>
            </motion.div>
          </Dialog>
        )}
      </AnimatePresence>
    </nav>
  );
}
