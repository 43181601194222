import { useEffect, useRef} from "react"
import { motion, useInView, useAnimation } from "framer-motion"
import { useTranslation } from 'react-i18next';

export default function Example() {

    const { t } = useTranslation()
    const container = useRef(null)
    const isInView = useInView(container)
    const animation = useAnimation()

    useEffect(() => {
      if(isInView) {
        animation.start({
          x:0,
          transition: {
            type: 'spring', duration: 2, bounce: 0.2
          }
        })

      }
    }, [isInView, animation]);


    return (
      <div className="relative bg-custom-rose">
        <div className="relative h-80 overflow-hidden bg-custom-light-orange md:absolute md:right-0 md:h-full md:w-1/3 lg:w-1/2">
          <img
            className="h-full w-full object-cover"
            src="/assets/img/IMG_0176-min.png"
            alt="Cuido de perros - perro en piscina"
          />
          <svg
            viewBox="0 0 926 676"
            aria-hidden="true"
            className="absolute left-24 -bottom-24 w-[57.875rem] transform-gpu blur-[118px]"
          >
            <path
              fill="url(#60c3c621-93e0-4a09-a0e6-4c228a0116d8)"
              fillOpacity=".4"
              d="m254.325 516.708-90.89 158.331L0 436.427l254.325 80.281 163.691-285.15c1.048 131.759 36.144 345.144 168.149 144.613C751.171 125.508 707.17-93.823 826.603 41.15c95.546 107.978 104.766 294.048 97.432 373.585L685.481 297.694l16.974 360.474-448.13-141.46Z"
            />
            <defs>
              <linearGradient
                id="60c3c621-93e0-4a09-a0e6-4c228a0116d8"
                x1="926.392"
                x2="-109.635"
                y1=".176"
                y2="321.024"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FF9693" />
                <stop offset={1} stopColor="#FFB5A5" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div ref={container} className="relative mx-auto max-w-7xl py-24 sm:py-32 lg:py-40 lg:px-8">
          <motion.div initial={{ x: '-100vw' }} animate={animation} className="pl-6 pr-6 md:w-2/3 md:pr-16 lg:w-1/2 lg:pr-24 xl:pr-32">
            <h2 className="text-base font-semibold leading-7 text-gray-200">{t('ctaHeading')}</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">{t('ctaTitle')}</p>
            <p className="mt-6 text-base leading-7 text-white">
              {t('ctaDescription')}
            </p>
            <div className="mt-8">
              <a
                href="/about"
                className="inline-flex rounded-md bg-custom-light-orange px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-white/20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                {t('ctaButton')}
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    )
  }
  