import { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function CEO() {
  const { t } = useTranslation();

  const container = useRef(null);
  const isInView = useInView(container);
  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start({
        y: 0,
        opacity: 1,
        transition: {
          type: "ease",
          duration: 1.5,
          ease: "easeOut",
        },
      });
    }
  }, [isInView, animation]);

  return (
    <div ref={container} className="overflow-hidden bg-custom-rose">
      <motion.div
        initial={{ y: 200 }}
        animate={animation}
        className="relative mx-auto max-w-7xl py-16 px-6 lg:px-8"
      >
        <div className="absolute top-0 bottom-0 left-3/4 hidden w-screen bg-custom-rose lg:block" />
        <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
          <div>
            <h2 className="text-lg font-semibold text-gray-100">{t("ceo")}</h2>
            <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-white sm:text-4xl">
              {t("meetYaraliz")}
            </h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:col-start-2 lg:row-start-1">
            <svg
              className="absolute top-0 right-0 -mt-20 -mr-20 hidden lg:block"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
              />
            </svg>
            <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                  <img
                    className="rounded-lg object-cover object-center shadow-lg"
                    src="assets/img/yariliz-image-1.jpg"
                    alt="Yaraliz Toledo"
                    width={1184}
                    height={1376}
                  />
                </div>
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="mx-auto max-w-prose text-base lg:max-w-none">
              <p className="text-lg text-white">{t("ceoDescriptionOne")}</p>
            </div>
            <div className="prose prose-indigo mx-auto mt-5 text-white lg:col-start-1 lg:row-start-1 lg:max-w-none">
              <p>{t("ceoDescriptionTwo")}</p>
              <p>{t("ceoDescriptionThree")}</p>
              <p>{t("ceoDescriptionFour")}</p>
              <p>{t("ceoDescriptionFive")}</p>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}
