/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import { useTranslation } from "react-i18next";
import Pagination from "./Pagination";

const images = [
  {
    id: 1,
    url: "/assets/img/JanDog.jpg",
    alt: "Jan Dog",
  },
  {
    id: 2,
    url: "/assets/img/KidannyDog.jpg",
    alt: "Kidanny Dog",
  },
  {
    id: 3,
    url: "/assets/img/LuisDog.jpg",
    alt: "Luis Dog",
  },
  {
    id: 4,
    url: "/assets/img/AlexanderDog.jpg",
    alt: "Alexander Dog",
  },
  {
    id: 5,
    url: "/assets/img/yariliz-image-1.jpg",
    alt: "Yaraliz Toledo",
  },
  {
    id: 6,
    url: "/assets/img/jan-image-2.jpg",
    alt: "Jan",
  },
  {
    id: 7,
    url: "/assets/img/yariliz-image-3.jpg",
    alt: "Valentines Day",
  },
  {
    id: 8,
    url: "/assets/img/yariliz-image-4.jpg",
    alt: "Pet Photoshooting",
  },
  {
    id: 9,
    url: "/assets/img/pets-image-2.jpg",
    alt: "Profesional Pet Photoshooting",
  },
  {
    id: 10,
    url: "/assets/img/kidanny-image-1.jpg",
    alt: "Kidanny",
  },
  {
    id: 11,
    url: "/assets/img/dalmata-black.jpg",
    alt: "Matilda",
  },
  {
    id: 12,
    url: "/assets/img/team-image-1.jpg",
    alt: "Team",
  },
  {
    id: 13,
    url: "/assets/img/luis-image-1.jpg",
    alt: "Luis",
  },
  {
    id: 14,
    url: "/assets/img/dalmata-image-1.jpg",
    alt: "Matilda Professional Photoshooting",
  },
  {
    id: 15,
    url: "/assets/img/pets-image-1.jpg",
    alt: "Christmas Professional Photoshooting",
  },
  {
    id: 16,
    url: "/assets/img/yariliz-image-2.jpg",
    alt: "Yaraliz Professional Photoshooting",
  },
  {
    id: 17,
    url: "/assets/img/IMG_0089-min.png",
    alt: "Luis & Sleeping Puppy",
  },
  {
    id: 18,
    url: "/assets/img/IMG_0176-min.png",
    alt: "Bull dog in pool day",
  },
  {
    id: 19,
    url: "/assets/img/IMG_0532-min.png",
    alt: "Yaraliz & Shih Tzu",
  },
  {
    id: 20,
    url: "/assets/img/IMG_1222-min.png",
    alt: "Pets & Friends",
  },
  {
    id: 21,
    url: "/assets/img/IMG_5578-min.png",
    alt: "Pets in Love",
  },
  {
    id: 22,
    url: "/assets/img/IMG_6259-min.png",
    alt: "Yaraliz Ester Photoshooting",
  },
  {
    id: 23,
    url: "/assets/img/IMG_6320-min.png",
    alt: "Twin Poodle",
  },
  {
    id: 24,
    url: "/assets/img/IMG_6445-min.png",
    alt: "Playing Pets",
  },
  {
    id: 25,
    url: "/assets/img/IMG_7843-min.png",
    alt: "Pets in pool",
  },
  {
    id: 26,
    url: "/assets/img/IMG_8327-min.png",
    alt: "Happy pets",
  },
  {
    id: 27,
    url: "/assets/img/IMG_8886-min.png",
    alt: "Happy dog",
  },
  {
    id: 28,
    url: "/assets/img/IMG_9525-min.png",
    alt: "Happy pet in pool",
  },
  {
    id: 29,
    url: "/assets/img/IMG_9907-min.png",
    alt: "Happy pets",
  },
  {
    id: 30,
    url: "/assets/img/IMG_9933-min.png",
    alt: "Happy pets in pool",
  },
];

export default function Gallery() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const container = useRef(null);
  const isInView = useInView(container);
  const animation = useAnimation();
  const [currentPage, setCurrentPage] = useState(1);
  const [imagesPerPage] = useState(6);
  const [animationKey, setAnimationKey] = useState(0);

  // Compute the number of pages based on the filtered data
  const totalPages = Math.ceil(images.length / imagesPerPage);

  // Compute the array of page numbers to display in the pagination component
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  // Get the index of the last image to display on the current page
  const indexOfLastImage = currentPage * imagesPerPage;

  // Get the index of the first image to display on the current page
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;

  // Get the current page of images
  const currentImages = images.slice(indexOfFirstImage, indexOfLastImage);

  // Handle changing the current page of images
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  function openModal(image) {
    setSelectedImage(image);
    setIsOpen(true);
  }

  function closeModal() {
    setSelectedImage(null);
    setIsOpen(false);
  }

  useEffect(() => {
    if (isInView) {
      animation.start({
        y: 0,
        opacity: 1,
        transition: {
          type: "ease",
          duration: 1.5,
          ease: "easeOut",
        },
      });
    }
  }, [isInView, animation]);

  const variants = {
    easeOut: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
      },
    },
  };

  useEffect(() => {
    setAnimationKey(animationKey + 1);
  }, [currentPage]);

  return (
    <div ref={container} className="bg-custom-rose">
      <motion.div initial={{ y: 100, opacity: 0 }} animate={animation}>
        <div className="bg-custom-rose py-6 sm:pt-12">
          <div className="mx-auto max-w-7xl px-6">
            <div className="mx-auto max-w-7xl">
              <p className="text-base font-semibold leading-7 text-gray-600">
                {t("galleryHeading")}
              </p>
              <h2 className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-6xl">
                {t("gallery")}
              </h2>
              <p className="mt-6 text-lg leading-8 text-white">
                {t("galleryDescription")}
              </p>
            </div>
          </div>
        </div>

        <section className="overflow-hidden text-neutral-700">
          <div className="container mx-auto px-5 py-2 lg:px-32 lg:py-12">
            <motion.div
              key={animationKey}
              initial={{ y: 25, opacity: 0 }}
              animate={"easeOut"}
              variants={variants}
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"
            >
              {currentImages.map((image) => (
                <div
                  key={image.id}
                  className="col-span-1 md:col-span-2 lg:col-span-1"
                >
                  <img
                    className="w-full h-full object-cover cursor-pointer rounded-md shadow-lg hover:transition hover:scale-105"
                    src={image.url}
                    alt={image.alt}
                    onClick={() => openModal(image)}
                  />
                </div>
              ))}
            </motion.div>
            <div className="mt-6">
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                pageNumbers={pageNumbers}
                onPageChange={handlePageChange}
              />
            </div>

            <Transition appear show={isOpen} as={Fragment}>
              <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={closeModal}
              >
                <div className="min-h-screen px-4 text-center">
                  <Transition.Child
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Dialog.Overlay
                      className="fixed inset-0 bg-black opacity-50 cursor-pointer"
                      onClick={() => closeModal()} // update the onClick event here
                    />
                  </Transition.Child>

                  <span
                    className="inline-block h-screen align-middle"
                    aria-hidden="true"
                  >
                    &#8203;
                  </span>
                  {selectedImage && (
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <div className="inline-block w-full max-w-3xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-custom-rose shadow-xl rounded-2xl">
                        <img
                          className="w-full"
                          src={selectedImage.url}
                          alt="Full-Screen Gallery"
                        />
                      </div>
                    </Transition.Child>
                  )}
                </div>
              </Dialog>
            </Transition>
          </div>
        </section>
      </motion.div>
    </div>
  );
}
