import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Services from './pages/Services';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';

function App() {
  return (
    <Router>
        <div className='bg-custom-light-orange overflow-x-hidden'>
          <NavBar />
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/about' element={<AboutUs />} />
            <Route exact path='/services' element={<Services />} />
            <Route exact path='/gallery' element={<Gallery />} />
            <Route exact path='/contact' element={<Contact />} />
          </Routes>
          <Footer />
        </div>
    </Router>
  )
}

export default App;