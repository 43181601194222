
import About from '../components/About';
import Ceo from '../components/Ceo';


export default function Home() {
    return (
        <div className="bg-custom-light-orange">
            <About />
            <Ceo />
        </div>
    )
}