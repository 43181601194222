import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

export default function BookingModal() {
  const [open, setOpen] = useState(true)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-custom-green px-6 py-6 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="flex flex-col items-center text-center">
                  <Dialog.Title as="h3" className="text-2xl sm:text-3xl font-semibold leading-6 text-white">
                    Book Your Pup's Adventure
                  </Dialog.Title>
                  <div className="mt-4">
                    <p className="text-base sm:text-lg text-gray-200">
                      To book your pup's adventure, download the app: <strong>Gingr for Pet Parents</strong>.
                    </p>
                    <p className="mt-4 text-lg sm:text-xl text-gray-200 font-bold">
                      Use our facility code:
                    </p>
                    <p className="text-2xl sm:text-3xl font-extrabold text-white mt-2">
                      558033
                    </p>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:flex sm:flex-col sm:items-center">
                  <a
                    className="mb-3 sm:mb-2 w-full inline-flex justify-center rounded-md bg-custom-light-orange px-4 py-3 text-base font-semibold text-white shadow-sm hover:bg-custom-rose"
                    href="https://apps.apple.com/us/app/gingr-for-pet-parents/id1580056094"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download on the App Store
                  </a>
                  <a
                    className="w-full inline-flex justify-center rounded-md bg-custom-light-orange px-4 py-3 text-base font-semibold text-white shadow-sm hover:bg-custom-rose"
                    href="https://play.google.com/store/apps/details?id=com.gingrapp.gingr&pcampaignid=web_share"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Get it on Google Play
                  </a>
                  <button
                    type="button"
                    className="mt-3 sm:mt-4 w-full inline-flex justify-center rounded-md bg-white px-4 py-3 text-base font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
