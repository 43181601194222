import React from 'react';
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next';

export default function Pagination({ currentPage, totalPages, onPageChange }) {
  const { t } = useTranslation()
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  function handlePreviousPage() {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  }

  function handleNextPage() {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  }

  return (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        <button
          onClick={handlePreviousPage}
          className="inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-white hover:border-gray-300 hover:text-gray-300">
          <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-white" aria-hidden="true" /> {t('previous')}
        </button>
      </div>
      <div className="hidden md:-mt-px md:flex">
            {pageNumbers.map((pageNumber) => (
                <div key={pageNumber} 
                className={`${currentPage === pageNumber 
                    ? 'cursor-pointer border-t-2 border-transparent text-white hover:border-gray-200 hover:text-white-200' 
                    : 'cursor-pointer border-t-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
                >
                    <button className="inline-flex items-center px-4 pt-4 text-sm font-medium" onClick={() => onPageChange(pageNumber)}>
                        {pageNumber}
                    </button>
                </div>
            ))}
        </div>
        <div className="-mt-px flex w-0 flex-1 justify-end">
            <button
            onClick={handleNextPage}
            className="inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-white hover:border-gray-300 hover:text-gray-300">
            {t('next')} <ArrowLongRightIcon className="ml-3 h-5 w-5 text-white" aria-hidden="true" />
            </button>
        </div>
    </nav>
  );
}